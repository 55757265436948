import api from "../api";

const getWaitingPageStatus = () => {
  return api.getApi().get(api.getBaseUrl() + "settings/waiting-page-status");
};
const updateWaitingPageStatus = (status) => {
  return api.getApi().post(api.getBaseUrl() + "settings/waiting-page-status", {
    status,
  });
};

const getSettings = () => {
  return api.getApi().get(api.getBaseUrl() + "settings/general");
};
const updateSettings = (settings) => {
  return api.getApi().put(api.getBaseUrl() + "settings/general", settings);
};
export { getWaitingPageStatus, updateWaitingPageStatus, getSettings, updateSettings };
