import api from "../api";

const login = (data) => {
  return api.getApiWithoutToken().post(api.getBaseUrl() + "login", data);
};
const registerUser = (data) => {
  return api.getApiWithoutToken().post(api.getBaseUrl() + "register", data);
};
const changePassword = (data) => {
  return api.getApi().post(api.getBaseUrl() + "ChangePassword", data);
};

export { login, registerUser, changePassword };
