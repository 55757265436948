import React from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { AdminLayout } from "../shared/layout";
import { getPlan, createPlan, updatePlan, getRoles } from "../../../data";
import { useNavigate, useParams } from "react-router-dom";
import { CardBox } from "../shared/CardBox";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Plus, Minus } from "lucide-react";

const LimitTypes = {
  RECORD_COUNT: "Kayıt Sayısı",
  AI_TOKEN: "AI Token",
  FILE_UPLOAD: "Dosya Yükleme",
  API_CALL: "API Çağrısı",
  FEATURE_USAGE: "Özellik Kullanımı",
  PROJECT_COUNT: "Proje Sayısı",
  TEAM_MEMBER_COUNT: "Takım Üyesi Sayısı",
  REPORT_FREQUENCY: "Rapor Sıklığı"
};

export function PlanManageScreen() {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const isEditMode = !!id;

  const { data: planData, isLoading: isPlanLoading } = useQuery({
    queryKey: ["plan", id],
    queryFn: () => getPlan(id),
    enabled: isEditMode,
  });

  const { data: rolesData, isLoading: isRolesLoading } = useQuery({
    queryKey: ["roles"],
    queryFn: getRoles,
  });

  const { control, handleSubmit, formState: { errors }, reset } = useForm({
    defaultValues: planData?.data || {}
  });

  React.useEffect(() => {
    if (planData?.data) {
      reset({
        ...planData.data,
        roles: planData.data.planRole?.map(pr => pr.role.id.toString()) || [],
      });
    }
  }, [planData, reset]);

  const { fields: featureFields, append: appendFeature, remove: removeFeature } = useFieldArray({
    control,
    name: "features"
  });

  const { fields: limitFields, append: appendLimit, remove: removeLimit } = useFieldArray({
    control,
    name: "limits"
  });

  const mutation = useMutation({
    mutationFn: (data) => isEditMode ? updatePlan(id, data) : createPlan(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["plans"]);
      navigate("/admin/plans");
    },
  });

  const onSubmit = (data) => {
    const formattedData = {
      ...data,
      roles: Array.isArray(data.roles) ? data.roles.map(roleId => parseInt(roleId, 10)) : [],
    };
    mutation.mutate(formattedData);
  };

  if ((isPlanLoading && isEditMode) || isRolesLoading) {
    return <AdminLayout title="Plan Yönetimi">Yükleniyor...</AdminLayout>;
  }

  return (
    <AdminLayout title={isEditMode ? "Plan Güncelle" : "Yeni Plan Ekle"}>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
        <div className="flex gap-8">
          <div className="w-1/3">
            <CardBox>
              <h2 className="text-2xl font-bold mb-4">Temel Bilgiler</h2>
              <div className="space-y-4">
                <div>
                  <Label htmlFor="name">Plan Adı</Label>
                  <Controller
                    name="name"
                    control={control}
                    rules={{ required: "Plan adı gereklidir" }}
                    render={({ field }) => <Input id="name" {...field} />}
                  />
                  {errors.name && <p className="text-red-500">{errors.name.message}</p>}
                </div>

                <div>
                  <Label htmlFor="description">Açıklama</Label>
                  <Controller
                    name="description"
                    control={control}
                    rules={{ required: "Açıklama gereklidir" }}
                    render={({ field }) => <Input id="description" {...field} />}
                  />
                  {errors.description && <p className="text-red-500">{errors.description.message}</p>}
                </div>

                <div>
                  <Label htmlFor="price">Fiyat</Label>
                  <Controller
                    name="price"
                    control={control}
                    rules={{ required: "Fiyat gereklidir" }}
                    render={({ field }) => <Input id="price" type="number" step="0.01" {...field} />}
                  />
                  {errors.price && <p className="text-red-500">{errors.price.message}</p>}
                </div>

                <div>
                  <Label htmlFor="planPriceId">Fiyat ID</Label>
                  <Controller
                    name="planPriceId"
                    control={control}
                    rules={{ required: "Fiyat ID gereklidir" }}
                    render={({ field }) => <Input id="planPriceId" {...field} />}
                  />
                  {errors.planPriceId && <p className="text-red-500">{errors.planPriceId.message}</p>}
                </div>

                <div>
                  <Label htmlFor="interval">Aralık</Label>
                  <Controller
                    name="interval"
                    control={control}
                    rules={{ required: "Aralık gereklidir" }}
                    render={({ field }) => <Input id="interval" {...field} />}
                  />
                  {errors.interval && <p className="text-red-500">{errors.interval.message}</p>}
                </div>

                <div className="flex items-center space-x-2">
                  <Controller
                    name="isActive"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        id="isActive"
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    )}
                  />
                  <Label htmlFor="isActive">Aktif</Label>
                </div>
              </div>
            </CardBox>
          </div>

          <div className="w-2/3 space-y-8">
            <CardBox>
              <h2 className="text-2xl font-bold mb-4">Özellikler</h2>
              <div className="space-y-4">
                {featureFields.map((field, index) => (
                  <div key={field.id} className="flex space-x-2">
                    <Controller
                      name={`features.${index}.name`}
                      control={control}
                      rules={{ required: "Özellik adı gereklidir" }}
                      render={({ field }) => <Input {...field} placeholder="Özellik adı" />}
                    />
                    <Controller
                      name={`features.${index}.icon`}
                      control={control}
                      rules={{ required: "İkon gereklidir" }}
                      render={({ field }) => <Input {...field} placeholder="İkon (örn: check)" />}
                    />
                    <Button type="button" variant="destructive" size="icon" onClick={() => removeFeature(index)}>
                      <Minus className="h-4 w-4" />
                    </Button>
                  </div>
                ))}
                <Button type="button" variant="outline" size="sm" onClick={() => appendFeature({ name: '', icon: '' })}>
                  <Plus className="h-4 w-4 mr-2" /> Özellik Ekle
                </Button>
              </div>
            </CardBox>

            <CardBox>
              <h2 className="text-2xl font-bold mb-4">Roller</h2>
              <div>
                <Label htmlFor="roles">Roller</Label>
                <Controller
                  name="roles"
                  control={control}
                  render={({ field }) => (
                    <select
                      className="w-full p-2 border rounded"
                      id="roles"
                      multiple
                      {...field}
                      value={Array.isArray(field.value) ? field.value : []}
                      onChange={(e) => field.onChange(Array.from(e.target.selectedOptions, option => option.value))}
                    >
                      {rolesData?.data?.map((role) => (
                        <option key={role.id} value={role.id.toString()}>
                          {role.name}
                        </option>
                      ))}
                    </select>
                  )}
                />
              </div>
            </CardBox>

           
          </div>
        </div>
        
        <div className="mt-8">
          <Button type="submit" className="w-full">{isEditMode ? "Plan Güncelle" : "Plan Oluştur"}</Button>
        </div>
      </form>
    </AdminLayout>
  );
}