import { Navigate } from "react-router-dom";
import AuthStore from "../stores/AuthStore";
import { observer } from "mobx-react";
import { Button } from "@/components/ui/button";
import { AlertCircle } from "lucide-react";

export const ProtectedAdminRoute = observer(({ children, permission }) => {
  const isHavePermission = AuthStore.isHavePermission(permission);

  if (!isHavePermission) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-background">
        <AlertCircle className="w-16 h-16 text-destructive mb-4" />
        <h1 className="text-4xl font-bold mb-2">Erişim Reddedildi</h1>
        <p className="text-muted-foreground mb-4">Bu sayfaya erişim izniniz yok.</p>
        <Button variant="outline" onClick={() => window.history.back()}>
          Geri Dön
        </Button>
      </div>
    );
  }

  return children;
});