import React from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AppLayout } from "../shared/layout";
import { getPrompts, deletePrompt, sharePrompt } from "../../../data";
import { useNavigate } from "react-router-dom";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Pencil, Trash, Share } from "lucide-react";

export function PromptListScreen() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data: prompts, isLoading } = useQuery({
    queryKey: ["prompts"],
    queryFn: getPrompts,
  });

  const deleteMutation = useMutation({
    mutationFn: deletePrompt,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["prompts"] });
    },
  });

  const shareMutation = useMutation({
    mutationFn: ({ id, email }) => sharePrompt(id, email),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["prompts"] });
    },
  });

  const handleDelete = (id) => {
    deleteMutation.mutate(id);
  };

  const handleShare = (id) => {
    const email = prompt("Paylaşmak istediğiniz kullanıcının e-posta adresini girin:");
    if (email) {
      shareMutation.mutate({ id, email });
    }
  };

  const handleAddNew = () => {
    navigate("/dashboard/prompts/add");
  };

  return (
    <AppLayout 
      title="Promptlar" 
      actions={[{ name: "Yeni Prompt", onClick: handleAddNew }]}
    >
      {isLoading ? (
        <p>Yükleniyor...</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {prompts.map((prompt) => (
            <Card key={prompt.id}>
              <CardHeader>
                <CardTitle>{prompt.title}</CardTitle>
                <CardDescription>{prompt.description}</CardDescription>
              </CardHeader>
              <CardContent>
                <p className="text-sm text-gray-600">Tip: {prompt.type}</p>
                {prompt.tags && prompt.tags.length > 0 && (
                  <div className="mt-2">
                    {prompt.tags.map((tag, index) => (
                      <span key={index} className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                        {tag}
                      </span>
                    ))}
                  </div>
                )}
              </CardContent>
              <CardFooter className="flex justify-between">
                <Button variant="outline" size="sm" onClick={() => navigate(`/dashboard/prompts/${prompt.id}`)}>
                  <Pencil className="mr-2 h-4 w-4" />
                  Düzenle
                </Button>
                <Button variant="outline" size="sm" onClick={() => handleShare(prompt.id)}>
                  <Share className="mr-2 h-4 w-4" />
                  Paylaş
                </Button>
                <Button variant="destructive" size="sm" onClick={() => handleDelete(prompt.id)}>
                  <Trash className="mr-2 h-4 w-4" />
                  Sil
                </Button>
              </CardFooter>
            </Card>
          ))}
        </div>
      )}
    </AppLayout>
  );
}