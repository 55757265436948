import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { getChatSetupData } from "../../../data";
import { AppLayout } from "../shared/layout";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  CardFooter,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Check, Star } from "lucide-react";
import { cn } from "@/lib/utils";

export function ChatSetupScreen() {
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedPrompts, setSelectedPrompts] = useState([]);
  const navigate = useNavigate();

  const { data: chatSetupData, isLoading } = useQuery({
    queryKey: ["chatSetupData"],
    queryFn: getChatSetupData,
  });

  const handleProjectSelect = (projectId) => {
    setSelectedProject(projectId);
  };

  const handlePromptToggle = (promptId) => {
    setSelectedPrompts((prev) =>
      prev.includes(promptId)
        ? prev.filter((id) => id !== promptId)
        : [...prev, promptId]
    );
  };

  const handleStartChat = () => {
    if (selectedProject && selectedPrompts.length > 0) {
      const promptIds = selectedPrompts.join(",");
      navigate(`/dashboard/chat/${selectedProject}/${promptIds}`);
    }
  };

  const handleFavoritesClick = () => {
    navigate("/dashboard/chat/favorites");
  };

  if (isLoading) {
    return <AppLayout>Yükleniyor...</AppLayout>;
  }

  const actions = [
    {
      name: "Favorilerim",
      icon: <Star className="mr-2" />,
      onClick: handleFavoritesClick,
    },
  ];

  return (
    <AppLayout title="Chat Kurulumu" actions={actions}>
      <div className=" mx-auto space-y-6">
        <section>
          <Card>
            <CardHeader>
              <CardTitle>Proje Seçimi</CardTitle>
              <CardDescription>Sohbet için bir proje seçin.</CardDescription>
            </CardHeader>
            <CardContent>
              <ScrollArea className="h-[300px]">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
                  {chatSetupData?.projects.map((project) => (
                    <Card
                      key={project.id}
                      className={cn(
                        "cursor-pointer transition-all",
                        selectedProject === project.id
                          ? "border-primary"
                          : "hover:border-accent"
                      )}
                      onClick={() => handleProjectSelect(project.id)}
                    >
                      <CardHeader className="relative">
                        <CardTitle className="pr-8 text-sm">
                          {project.name}
                        </CardTitle>
                        {selectedProject === project.id && (
                          <Check className="absolute top-2 right-2 h-4 w-4 text-primary" />
                        )}
                      </CardHeader>
                      <CardContent>
                        <p className="text-xs text-muted-foreground line-clamp-2">
                          {project.description.substring(0, 100)}
                        </p>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              </ScrollArea>
            </CardContent>
          </Card>
        </section>

        <section>
          <Card>
            <CardHeader>
              <CardTitle>Prompt Seçimi</CardTitle>
              <CardDescription>
                Sohbette kullanmak istediğiniz promptları seçin.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <ScrollArea className="h-[400px]">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
                  {chatSetupData?.prompts.map((prompt) => (
                    <Card
                      key={prompt.id}
                      className={cn(
                        "cursor-pointer transition-all",
                        selectedPrompts.includes(prompt.id)
                          ? "border-primary"
                          : "hover:border-accent"
                      )}
                      onClick={() => handlePromptToggle(prompt.id)}
                    >
                      <CardHeader className="relative">
                        <CardTitle className="pr-8 text-sm">
                          {prompt.title}
                        </CardTitle>
                        {selectedPrompts.includes(prompt.id) && (
                          <Check className="absolute top-2 right-2 h-4 w-4 text-primary" />
                        )}
                      </CardHeader>
                      <CardContent>
                        <p className="text-xs text-muted-foreground line-clamp-2">
                          {prompt.content.substring(0, 100)}
                        </p>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              </ScrollArea>
            </CardContent>
          </Card>
        </section>

        <Card>
          <CardContent className="pt-6">
            <Button
              onClick={handleStartChat}
              disabled={!selectedProject || selectedPrompts.length === 0}
              className="w-full"
            >
              Sohbeti Başlat
            </Button>
          </CardContent>
        </Card>
      </div>
    </AppLayout>
  );
}
