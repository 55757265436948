import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { getFavoriteChats } from '@/data';
import { useNavigate } from 'react-router-dom';
import { AppLayout } from '../shared/layout';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { ArrowRight, MessageCircle, Folder } from 'lucide-react';

export function FavoriteChatsScreen() {
  const navigate = useNavigate();
  const { data: favoriteChats, isLoading } = useQuery({
    queryKey: ['favoriteChats'],
    queryFn: getFavoriteChats
  });

  const handleContinueChat = (chat) => {
    const url = `/dashboard/chat/${chat.projectId}/${chat.promptIds.join(',')}?task=${encodeURIComponent(chat.taskDescription)}&chatId=${chat.id}`;
    navigate(url);
  };

  if (isLoading) {
    return <AppLayout>Yükleniyor...</AppLayout>;
  }

  return (
    <AppLayout title="Favori Sohbetler">
      <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {favoriteChats && favoriteChats.length > 0 ? (
          favoriteChats.map((chat) => (
            <Card key={chat.id} className="hover:shadow-md transition-shadow">
              <CardHeader>
                <CardTitle className="text-lg flex items-center">
                  <MessageCircle className="mr-2 h-5 w-5" />
                  {chat.title}
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="mb-2">
                  <p className="text-sm text-gray-500 flex items-center">
                    <Folder className="mr-2 h-4 w-4" />
                    Proje: {chat.project.name}
                  </p>
                </div>
                <div className="mb-2">
                  <p className="text-sm text-gray-500">Promptlar:</p>
                  <ul className="list-disc list-inside">
                    {chat.prompts.map((prompt, index) => (
                      <li key={index} className="text-sm text-gray-600">{prompt.title}</li>
                    ))}
                  </ul>
                </div>
                <div className="mb-2">
                  <p className="text-sm text-gray-500">Oluşturulma Tarihi: {new Date(chat.createdAt).toLocaleString()}</p>
                </div>
                <Button 
                  onClick={() => handleContinueChat(chat)}
                  className="mt-2 w-full"
                >
                  Devam Et
                  <ArrowRight className="ml-2 h-4 w-4" />
                </Button>
              </CardContent>
            </Card>
          ))
        ) : (
          <Card>
            <CardContent className="text-center py-6">
              <p>Henüz favori sohbet bulunmamaktadır.</p>
            </CardContent>
          </Card>
        )}
      </div>
    </AppLayout>
  );
}
