import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocalObservable } from 'mobx-react-lite';
import settingsStore from '@/stores/SettingStore';

const SettingsContext = createContext(null);

export const SettingsProvider = ({ children }) => {
  const store = useLocalObservable(() => settingsStore);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    store.fetchSettings().finally(() => setIsLoading(false));
  }, [store]);

  if (isLoading) {
    return <div>Yükleniyor...</div>; // veya başka bir yükleme göstergesi
  }

  return (
    <SettingsContext.Provider value={store}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => {
  const store = useContext(SettingsContext);
  if (!store) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }
  return store;
};