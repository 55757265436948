import React from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { getUserChats, deleteChat, toggleFavorite } from '@/data';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Star, Trash } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export function ChatList({ projectId, onChatSelect }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { data: chats, isLoading } = useQuery({
    queryKey: ['chats', projectId],
    queryFn: () => getUserChats(projectId)
  });

  const deleteMutation = useMutation({
    mutationFn: deleteChat,
    onSuccess: () => {
      queryClient.invalidateQueries(['chats', projectId]);
    },
  });

  const favoriteMutation = useMutation({
    mutationFn: toggleFavorite,
    onSuccess: () => {
      queryClient.invalidateQueries(['chats', projectId]);
    },
  });

  const handleContinueChat = (chat) => {
    const url = `/dashboard/chat/${projectId}/${chat.promptIds.join(',')}?task=${encodeURIComponent(chat.taskDescription)}&chatId=${chat.id}`;
    navigate(url);
  };

  if (isLoading) return <div>Yükleniyor...</div>;

  return (
    <div className="space-y-4">
      {chats && chats.length > 0 ? (
        chats.map((chat) => (
          <Card key={chat.id}>
            <CardHeader>
              <CardTitle>{chat.title}</CardTitle>
            </CardHeader>
            <CardContent>
              <p>Oluşturulma Tarihi: {new Date(chat.createdAt).toLocaleString()}</p>
              <div className="flex justify-between mt-2">
                <Button onClick={() => handleContinueChat(chat)}>Devam Et</Button>
                <div>
                  <Button
                    variant="ghost"
                    onClick={() => favoriteMutation.mutate(chat.id)}
                  >
                    <Star className={chat.isFavorite ? 'text-yellow-500' : 'text-gray-500'} />
                  </Button>
                  <Button
                    variant="ghost"
                    onClick={() => deleteMutation.mutate(chat.id)}
                  >
                    <Trash className="text-red-500" />
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        ))
      ) : (
        <div>Bu proje için henüz kaydedilmiş sohbet bulunmamaktadır.</div>
      )}
    </div>
  );
}
