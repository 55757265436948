import React from "react";
import { Link, useNavigate } from "react-router-dom";

import AuthStore from "../../../stores/AuthStore";
import { AuthLayout } from "../shared/layout";
import { useForm } from "react-hook-form";
import { getProfile, login } from "../../../data";
import { useMutation, useQuery } from "@tanstack/react-query";

export function LoginScreen({ navigation }) {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const {
    mutate: loginUser,
    isSuccess,
    error,
  } = useMutation({
    mutationKey: "login",
    mutationFn: (data) => {
      return login(data);
    },
    onSuccess: (response) => {
      AuthStore.setUser(response.data.token);
      navigate("/dashboard");
    },
  });

  const onSubmit = async (data) => {
    const { email, password } = data;
    loginUser({ email, password });
  };

  return (
    <AuthLayout>
      <h2 className="text-gray-900 text-lg font-medium title-font mb-5">
        Sign In
      </h2>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className=" mb-4">
          <label htmlFor="full-name" className="leading-7 text-sm ">
            Username or email
          </label>
          <input
            type="text"
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: "Invalid email format",
              },
            })}
            placeholder="Username"
            className="w-full   border  border-gray-300   text-base  py-1 px-3 leading-8 "
          />
          {errors.email && <span>{errors.email.message}</span>}
        </div>

        <div className=" mb-4">
          <label htmlFor="email" className="leading-7 text-sm">
            Email
          </label>
          <input
            type="password"
            {...register("password", {
              required: "Password is required",
            })}
            placeholder="Password"
            className="w-full   border  border-gray-300   text-base  py-1 px-3 leading-8 "
          />
          {errors.password && <span>{errors.password.message}</span>}
        </div>

        {error && <p>{error.message}</p>}
        <button
          className="shadow-lg  bg-green-200 text-center border-0 py-2 px-8   rounded text-lg"
          type="submit"
        >
          Login
        </button>
      </form>
      <Link
        className="shadow-lg  text-center border-0 py-2 px-8   text-lg mt-5 "
        to="/register"
      >
        Register
      </Link>
    </AuthLayout>
  );
}
