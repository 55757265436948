import React from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm, Controller } from "react-hook-form";
import { AppLayout } from "../shared/layout";
import { getPrompt, createPrompt, updatePrompt } from "../../../data";
import { useNavigate, useParams } from "react-router-dom";
import { CardBox } from "@/screens/admin/shared/CardBox";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { toast } from "@/components/ui/use-toast";

export function PromptManageScreen() {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const isEditMode = !!id;

  const { data: promptData, isLoading: isPromptLoading } = useQuery({
    queryKey: ["prompt", id],
    queryFn: () => getPrompt(id),
    enabled: isEditMode,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  React.useEffect(() => {
    if (promptData) {
      reset({
        title: promptData.title || "",
        content: promptData.content || "",
      });
    }
  }, [promptData, reset]);

  const mutation = useMutation({
    mutationFn: (data) =>
      isEditMode ? updatePrompt(id, data) : createPrompt(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["prompts"]);
      toast({
        title: "Başarılı",
        description: isEditMode ? "Prompt güncellendi" : "Prompt oluşturuldu",
        variant: "success",
      });
      navigate("/dashboard/prompts");
    },
    onError: (error) => {
      toast({
        title: "Hata",
        description: "Bir hata oluştu",
        variant: "destructive",
      });
      console.error("Prompt işlem hatası:", error);
    },
  });

  const onSubmit = (data) => {
    mutation.mutate(data);
  };

  if (isPromptLoading && isEditMode) {
    return <AppLayout title="Prompt Yönetimi">Yükleniyor...</AppLayout>;
  }

  return (
    <AppLayout title={isEditMode ? "Prompt Güncelle" : "Yeni Prompt Ekle"}>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
        <CardBox>
          <h2 className="text-2xl font-bold mb-4">Prompt Bilgileri</h2>
          <div className="space-y-4">
            <div>
              <Label htmlFor="title">Başlık</Label>
              <Controller
                name="title"
                control={control}
                rules={{ required: "Başlık gereklidir" }}
                render={({ field }) => <Input id="title" {...field} />}
              />
              {errors.title && (
                <p className="text-red-500">{errors.title.message}</p>
              )}
            </div>

            <div>
              <Label htmlFor="content">İçerik</Label>
              <Controller
                name="content"
                control={control}
                rules={{ required: "İçerik gereklidir" }}
                render={({ field }) => <Textarea id="content" {...field} />}
              />
              {errors.content && (
                <p className="text-red-500">{errors.content.message}</p>
              )}
            </div>
          </div>
        </CardBox>

        <Button type="submit" className="w-full">
          {isEditMode ? "Prompt Güncelle" : "Prompt Oluştur"}
        </Button>
      </form>
    </AppLayout>
  );
}
