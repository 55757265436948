import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { CheckCircle, Zap, Shield, Smartphone } from "lucide-react";
import { NavbarLandingPage } from "@/components";
import { LandingPageLayout } from "../shared/layout";

export function LandingPageScreen() {
  const features = [
    "Hızlı Başlangıç Şablonu",
    "Kullanıcı Yönetimi",
    "Rol Tabanlı Yetkilendirme",
    "Responsive Tasarım",
    "API Entegrasyonu",
    "Güvenli Kimlik Doğrulama",
  ];

  return (
    <div className="flex flex-col min-h-screen">
      <NavbarLandingPage />

      <header className="bg-gradient-to-r from-purple-600 to-indigo-700 text-white py-20">
        <div className="container mx-auto text-center px-4">
          <h1 className="text-5xl font-bold mb-4">Kendi AI Sohbet Botunuzu Oluşturun</h1>
          <p className="text-xl mb-8">Aifelo ile özel AI asistanlarınızı tasarlayın ve onlarla sohbet edin</p>
          <Link to="/login">
            <Button size="lg" className="bg-white text-purple-600 hover:bg-purple-100">
              Ücretsiz Deneyin
            </Button>
          </Link>
        </div>
      </header>

      <main className="flex-grow">
        <section className="py-16">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold mb-8 text-center">Neden Aifelo?</h2>
            <div className="grid md:grid-cols-3 gap-8">
              {[
                { title: "Yapay Zeka Entegrasyonu", desc: "En son AI teknolojileriyle güçlendirilmiş botlar oluşturun" },
                { title: "Özelleştirilebilir", desc: "Botunuzun kişiliğini ve bilgi tabanını özelleştirin" },
                { title: "Gerçek Zamanlı Sohbet", desc: "Oluşturduğunuz botlarla anında iletişim kurun" }
              ].map((feature, index) => (
                <Card key={index}>
                  <CardHeader>
                    <CardTitle>{feature.title}</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <p>{feature.desc}</p>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        </section>

        <section className="bg-gray-100 py-16">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold mb-8 text-center">Özellikler</h2>
            <ul className="grid md:grid-cols-2 gap-4">
              {[
                "Sınırsız bot oluşturma",
                "Gelişmiş NLP entegrasyonu",
                "Çoklu dil desteği",
                "Özelleştirilebilir arayüz",
                "Veri analizi ve raporlama",
                "7/24 teknik destek"
              ].map((feature, index) => (
                <li key={index} className="flex items-center">
                  <CheckCircle className="text-green-500 mr-2" />
                  <span>{feature}</span>
                </li>
              ))}
            </ul>
          </div>
        </section>

        <section className="py-16">
          <div className="container mx-auto text-center px-4">
            <h2 className="text-3xl font-bold mb-8">Hemen Başlayın</h2>
            <p className="text-xl mb-8">
              Kendi AI asistanınızı oluşturmak için ücretsiz hesabınızı açın
            </p>
            <Link to="/login">
              <Button size="lg" className="bg-purple-600 text-white hover:bg-purple-700">
                Ücretsiz Kaydol
              </Button>
            </Link>
          </div>
        </section>
      </main>

      <footer className="bg-gray-800 text-white py-8">
        <div className="container mx-auto text-center px-4">
          <p>&copy; 2024 Aifelo. Tüm hakları saklıdır.</p>
        </div>
      </footer>
    </div>
  );
}