import { AdminLayout } from "../shared/layout";

export function AdminDashboardScreen() {
  return (
    <AdminLayout title="Dashboard">
      <main>
        <h1>Dashboard screen for admin </h1>
      </main>
    </AdminLayout>
  );
}
