import React from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getUsers, deleteUser } from "../../../data";
import { AdminLayout } from "../shared/layout";
import { useNavigate } from "react-router-dom";
import { Eye, Pencil, Trash2, UserPlus } from "lucide-react";
import { DataTable } from "@/components/table";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { toast } from "@/components/ui/use-toast";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";

export function UserListScreen() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery({
    queryKey: ["users"],
    queryFn: getUsers,
  });

  const deleteMutation = useMutation({
    mutationFn: deleteUser,
    onSuccess: () => {
      queryClient.invalidateQueries(["users"]);
      toast({
        title: "Başarılı",
        description: "Kullanıcı başarıyla silindi",
        variant: "success",
      });
    },
    onError: (error) => {
      toast({
        title: "Hata",
        description: "Kullanıcı silinirken bir hata oluştu",
        variant: "destructive",
      });
      console.error("Kullanıcı silme hatası:", error);
    },
  });

  const handleDelete = (id) => {
    deleteMutation.mutate(id);
  };

  const actions = [
    {
      name: "Kullanıcı Ekle",
      icon: <UserPlus className="mr-2 h-4 w-4" />,
      onClick: () => navigate("/admin/users/new"),
    },
  ];

  const getRoleBadge = (roles) => {
    if (!roles || roles.length === 0) return <Badge variant="outline">Rol Yok</Badge>;
    
    const roleColors = {
      ADMIN: "red",
      USER: "blue",
      MODERATOR: "green",
      // Diğer roller için renkleri buraya ekleyebilirsiniz
    };

    return roles.map((roleObj, index) => (
      <Badge 
        key={index} 
        variant="outline" 
        className="mr-1"
        style={{ backgroundColor: roleColors[roleObj.role.name] || "gray", color: "white" }}
      >
        {roleObj.role.name}
      </Badge>
    ));
  };

  const columns = [
    { key: "name", label: "İsim" },
    { key: "email", label: "E-posta" },
    { 
      key: "", 
      label: "Roller", 
      render: (row) => getRoleBadge(row.roles)
    },
    { 
      key: "actions", 
      label: "İşlemler", 
      render: (row) => (
        <div className="flex space-x-2">
      
        <Button 
          variant="ghost" 
          size="icon" 
          onClick={() => navigate(`/admin/users/${row.id}`)}
          className="hover:bg-green-100"
        >
          <Pencil className="h-4 w-4 text-green-500" />
        </Button>
        <AlertDialog>
          <AlertDialogTrigger asChild>
            <Button 
              variant="ghost" 
              size="icon" 
              className="hover:bg-red-100"
            >
              <Trash2 className="h-4 w-4 text-red-500" />
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Kullanıcıyı Sil</AlertDialogTitle>
              <AlertDialogDescription>
                Bu kullanıcıyı silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>İptal</AlertDialogCancel>
              <AlertDialogAction onClick={() => handleDelete(row.id)}>Sil</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
        </div>
      )
    },
  ];

  return (
    <AdminLayout
      title="Kullanıcılar"
      description="Kullanıcılarınızı buradan yönetin."
      actions={actions}
      loading={isLoading}
    >
      {data && (
        <DataTable 
          data={data.data} 
          title="Kullanıcı Listesi"
          columns={columns}
          enableSearch={true}
          enableSort={true}
          enablePagination={true}
          itemsPerPage={10}
        />
      )}
    </AdminLayout>
  );
}