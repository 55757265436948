import React, { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getProject, getPrompt, getChatById } from "../../../data";
import { AIChat } from "./aichat";
import { ChatList } from "./list";
import { AppLayout } from "../shared/layout";
import ProjectEditForm from "./project-edit-form";
import PromptsEditForm from "./prompt-edit-form";
import { Button } from "../../../components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";

export function ChatScreen() {
  const { projectId, promptIds } = useParams();
  const [searchParams] = useSearchParams();
  const taskDescription = searchParams.get("task");
  const chatId = searchParams.get("chatId");
  const [selectedChat, setSelectedChat] = useState(null);
  const [isEditingProject, setIsEditingProject] = useState(false);
  const [isEditingPrompts, setIsEditingPrompts] = useState(false);

  const queryClient = useQueryClient();

  const { data: project, isLoading: isLoadingProject } = useQuery({
    queryKey: ["project", projectId],
    queryFn: () => getProject(projectId),
  });

  const promptIdArray = promptIds.split(",");
  const { data: prompts, isLoading: isLoadingPrompts } = useQuery({
    queryKey: ["prompts", promptIdArray],
    queryFn: () => Promise.all(promptIdArray.map((id) => getPrompt(id))),
  });

  const { data: chatData, isLoading: isLoadingChat } = useQuery({
    queryKey: ["chat", chatId],
    queryFn: () => getChatById(chatId),
    enabled: !!chatId,
  });

  useEffect(() => {
    if (chatData) {
      setSelectedChat(chatData);
    }
  }, [chatData]);

  if (isLoadingProject || isLoadingPrompts || (chatId && isLoadingChat)) {
    return <AppLayout>Yükleniyor...</AppLayout>;
  }

  if (!project || !prompts) {
    return <AppLayout>Proje veya promptlar bulunamadı.</AppLayout>;
  }

  return (
    <AppLayout title={`${project.name} Chat`}>
      <div className="flex">
        <div className="w-1/4 pr-4">
          <ChatList projectId={projectId} onChatSelect={setSelectedChat} />
        </div>
        <div className="w-1/2">
          <AIChat
            projectId={projectId}
            promptIds={promptIdArray}
            taskDescription={taskDescription}
            initialChat={selectedChat}
          />
        </div>
        <div className="w-1/4 pl-4 space-y-4">
          <Card>
            <CardHeader>
              <CardTitle>Project: {project.name}</CardTitle>
            </CardHeader>
            <CardContent>
              {isEditingProject ? (
                <ProjectEditForm
                  project={project}
                  onCancel={() => setIsEditingProject(false)}
                  onSave={() => {
                    setIsEditingProject(false);
                    queryClient.invalidateQueries(["project", projectId]);
                  }}
                />
              ) : (
                <>
                  <p className="mb-2">{project.description}</p>
                  <Button onClick={() => setIsEditingProject(true)}>
                    Edit Project
                  </Button>
                </>
              )}
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Prompts</CardTitle>
            </CardHeader>
            <CardContent>
              {isEditingPrompts ? (
                <PromptsEditForm
                  prompts={prompts}
                  onCancel={() => setIsEditingPrompts(false)}
                  onSave={() => {
                    setIsEditingPrompts(false);
                    queryClient.invalidateQueries(["prompts", promptIdArray]);
                  }}
                />
              ) : (
                <>
                  {prompts.map((prompt) => (
                    <div key={prompt.id} className="mb-2">
                      <h3 className="font-semibold">{prompt.title}</h3>
                      <p className="text-sm text-gray-600">{prompt.content}</p>
                    </div>
                  ))}
                  <Button onClick={() => setIsEditingPrompts(true)}>
                    Edit Prompts
                  </Button>
                </>
              )}
            </CardContent>
          </Card>
        </div>
      </div>
    </AppLayout>
  );
}
