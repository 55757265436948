import { Link, useNavigate } from "react-router-dom";
import AuthStore from "../../stores/AuthStore";
import { useQueryClient } from "@tanstack/react-query";
import { Button } from "@/components/ui/button";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { observer } from "mobx-react-lite";
import { User } from "lucide-react";

export const NavbarApp = observer(() => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const logout = () => {
    queryClient.clear();
    AuthStore.logout();
    navigate("/");
  };

  const handleNavigation = (path, requiredPermission) => {
    console.log("requiredPermission", requiredPermission);
    if (!requiredPermission || AuthStore.isHavePermission(requiredPermission)) {
      navigate(path);
    }
  };

  return (
    <nav className="border-b-2">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <Link
              className="flex title-font font-medium items-center text-gray-900"
              to="/"
            >
              Aifelo
            </Link>

            <NavigationMenu>
              <NavigationMenuList>
                <NavigationMenuItem>
                  <NavigationMenuLink 
                    className={navigationMenuTriggerStyle()}
                    onClick={() => handleNavigation("/dashboard")}
                  >
                    Dashboard
                  </NavigationMenuLink>
                </NavigationMenuItem>
                <NavigationMenuItem>
                  <NavigationMenuLink 
                    className={navigationMenuTriggerStyle()}
                    onClick={() => handleNavigation("/dashboard/projects", "PROJECT_READ")}
                    style={{ 
                      opacity: AuthStore.isHavePermission("PROJECT_READ") ? 1 : 0.5, 
                      cursor: AuthStore.isHavePermission("PROJECT_READ") ? "pointer" : "not-allowed" 
                    }}
                  >
                    Projeler
                  </NavigationMenuLink>
                </NavigationMenuItem>
                <NavigationMenuItem>
                  <NavigationMenuLink 
                    className={navigationMenuTriggerStyle()}
                    onClick={() => handleNavigation("/dashboard/prompts", "PROMPT_READ")}
                    style={{ 
                      opacity: AuthStore.isHavePermission("PROMPT_READ") ? 1 : 0.5, 
                      cursor: AuthStore.isHavePermission("PROMPT_READ") ? "pointer" : "not-allowed" 
                    }}
                  >
                    Promptlar
                  </NavigationMenuLink>
                </NavigationMenuItem>
                <NavigationMenuItem>
                  <NavigationMenuLink 
                    className={navigationMenuTriggerStyle()}
                    onClick={() => handleNavigation("/dashboard/chat", "PROMPT_EXECUTE")}
                    style={{ 
                      opacity: AuthStore.isHavePermission("PROMPT_EXECUTE") ? 1 : 0.5, 
                      cursor: AuthStore.isHavePermission("PROMPT_EXECUTE") ? "pointer" : "not-allowed" 
                    }}
                  >
                    Chat
                  </NavigationMenuLink>
                </NavigationMenuItem>
              </NavigationMenuList>
            </NavigationMenu>
          </div>

          <div className="flex items-center space-x-4">
            {AuthStore.isHavePermission("ADMIN") && (
              <Button variant="outline" asChild>
                <Link to="/admin">Admin</Link>
              </Button>
            )}
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="relative h-8 w-8 rounded-full">
                  <Avatar className="h-8 w-8">
                    <AvatarImage src="/path-to-your-avatar-image.jpg" alt="@username" />
                    <AvatarFallback><User className="h-4 w-4" /></AvatarFallback>
                  </Avatar>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56" align="end" forceMount>
                <DropdownMenuLabel className="font-normal">
                  <div className="flex flex-col space-y-1">
                    <p className="text-sm font-medium leading-none">username</p>
                    <p className="text-xs leading-none text-muted-foreground">
                      user@example.com
                    </p>
                  </div>
                </DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem asChild>
                  <Link to="/profile">Profile</Link>
                </DropdownMenuItem>
                <DropdownMenuItem onClick={logout}>
                  Log out
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>
    </nav>
  );
});