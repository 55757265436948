import React from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm, Controller } from "react-hook-form";
import { AdminLayout } from "../shared/layout";
import { getUser, createUser, updateUser, getRoles } from "../../../data";
import { useNavigate, useParams } from "react-router-dom";
import { CardBox } from "../shared/CardBox";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";
import { toast } from "@/components/ui/use-toast";

export function UserManageScreen() {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const isEditMode = !!id;

  const { data: userData, isLoading: isUserLoading } = useQuery({
    queryKey: ["user", id],
    queryFn: () => getUser(id),
    enabled: isEditMode,
  });

  const { data: rolesData, isLoading: isRolesLoading, error: rolesError } = useQuery({
    queryKey: ["roles"],
    queryFn: getRoles,
  });

  const { control, handleSubmit, formState: { errors }, reset } = useForm({
    defaultValues: React.useMemo(() => ({
      name: userData?.data?.name || "",
      email: userData?.data?.email || "",
      roles: userData?.data?.roles?.map(r => r.roleId.toString()) || [],
    }), [userData?.data])
  });

  React.useEffect(() => {
    if (userData?.data) {
      reset({
        name: userData.data.name,
        email: userData.data.email,
        roles: userData.data.roles.map(r => r.roleId.toString()),
      });
    }
  }, [userData?.data, reset]);

  const mutation = useMutation({
    mutationFn: (data) => isEditMode ? updateUser(id, data) : createUser(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["users"]);
      toast({
        title: "Başarılı",
        description: isEditMode ? "Kullanıcı güncellendi" : "Kullanıcı oluşturuldu",
        variant: "success",
      });
      navigate("/admin/users");
    },
    onError: (error) => {
      toast({
        title: "Hata",
        description: "Bir hata oluştu",
        variant: "destructive",
      });
      console.error("Kullanıcı işlem hatası:", error);
    },
  });

  const onSubmit = (data) => {
    const formattedData = {
      ...data,
      roles: Array.isArray(data.roles) ? data.roles.map(roleId => parseInt(roleId, 10)) : [],
    };
    mutation.mutate(formattedData);
  };

  if ((isUserLoading && isEditMode) || isRolesLoading) {
    return <AdminLayout title="Kullanıcı Yönetimi">Yükleniyor...</AdminLayout>;
  }

  return (
    <AdminLayout title={isEditMode ? "Kullanıcı Güncelle" : "Yeni Kullanıcı Ekle"}>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
        <CardBox>
          <h2 className="text-2xl font-bold mb-4">Kullanıcı Bilgileri</h2>
          <div className="space-y-4">
            <div>
              <Label htmlFor="name">İsim</Label>
              <Controller
                name="name"
                control={control}
                rules={{ required: "İsim gereklidir" }}
                render={({ field }) => <Input id="name" {...field} />}
              />
              {errors.name && <p className="text-red-500">{errors.name.message}</p>}
            </div>

            <div>
              <Label htmlFor="email">E-posta</Label>
              <Controller
                name="email"
                control={control}
                rules={{ required: "E-posta gereklidir", pattern: { value: /^\S+@\S+$/i, message: "Geçerli bir e-posta adresi giriniz" } }}
                render={({ field }) => <Input id="email" type="email" {...field} />}
              />
              {errors.email && <p className="text-red-500">{errors.email.message}</p>}
            </div>

            {!isEditMode && (
              <div>
                <Label htmlFor="password">Şifre</Label>
                <Controller
                  name="password"
                  control={control}
                  rules={{ required: "Şifre gereklidir", minLength: { value: 6, message: "Şifre en az 6 karakter olmalıdır" } }}
                  render={({ field }) => <Input id="password" type="password" {...field} />}
                />
                {errors.password && <p className="text-red-500">{errors.password.message}</p>}
              </div>
            )}

            <div>
              <Label>Roller</Label>
              <div className="space-y-2">
                {rolesData?.data?.map((role) => (
                  <div key={role.id} className="flex items-center">
                    <Controller
                      name="roles"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          id={`role-${role.id}`}
                          checked={field.value?.includes(role.id.toString())}
                          onCheckedChange={(checked) => {
                            const updatedRoles = checked
                              ? [...(field.value || []), role.id.toString()]
                              : field.value?.filter((id) => id !== role.id.toString()) || [];
                            field.onChange(updatedRoles);
                          }}
                        />
                      )}
                    />
                    <Label htmlFor={`role-${role.id}`} className="ml-2">
                      {role.name}
                    </Label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </CardBox>
        
        <Button type="submit" className="w-full">
          {isEditMode ? "Kullanıcı Güncelle" : "Kullanıcı Oluştur"}
        </Button>
      </form>
    </AdminLayout>
  );
}
