import React from "react";

import { AppLayout, DashboardLayout } from "../shared/layout";
import { useQuery } from "@tanstack/react-query";
import { getProfile } from "../../../data";
export function ProfileScreen() {
  const { data, isLoading, error } = useQuery({
    queryKey: ["profile"],
    queryFn: getProfile,
  });

  const pageSettings = {
    title: "Profile",
    actions: [],
  };

  return (
    <div>
      <AppLayout
        title={pageSettings.title}
        actions={pageSettings.actions}
        loading={isLoading}
      >
        {error ? (
          <div>Bu alani gorme yetkiniz yok</div>
        ) : (
          !isLoading && (
            <div>
              <div className="  ">
                <label>Name: </label>
                <span>{data.data.user.name}</span>
              </div>
              <div className="  ">
                <label>Email </label>
                <span>{data.data.user.email}</span>
              </div>
              <div className="">
                <label>Created At: </label>
                <span>{data.data.user.createdAt}</span>
              </div>
            </div>
          )
        )}
      </AppLayout>
    </div>
  );
}
