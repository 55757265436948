import api from "../api";

export const getSubscriptions = async () => {
  const response = await api.getApi().get("/subscriptions");
  return response.data;
};

export const deleteSubscription = async (id) => {
  const response = await api.getApi().delete(`/subscriptions/${id}`);
  return response.data;
};

export const updateSubscription = async (id, data) => {
  const response = await api.getApi().put(`/subscriptions/${id}`, data);
  return response.data;
};

export const createSubscription = async (data) => {
  const response = await api.getApi().post("/subscriptions", data);
  return response.data;
};

export const getSubscription = async (id) => {
  const response = await api.getApi().get(`/subscriptions/${id}`);
  return response.data;
};