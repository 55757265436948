import React from "react";

import { useStripe } from "@stripe/react-stripe-js";
import { createCheckoutSession } from "@/data/requests/stripe-request";
import { Button } from "@/components/ui/button";

const CheckoutButton = ({ priceId, className }) => {
  const stripe = useStripe();

  const handleClick = async () => {
    const response = await createCheckoutSession({ priceId });
    const { id } = await response.data;
    await stripe.redirectToCheckout({ sessionId: id });
  };

  return (
    <Button onClick={handleClick} className={className}>
      Abone Ol
    </Button>
  );
};

export default CheckoutButton;