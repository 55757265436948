import React from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AppLayout } from "../shared/layout";
import { getProjects, deleteProject, shareProject } from "../../../data";
import { useNavigate } from "react-router-dom";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Pencil, Trash, Share } from "lucide-react";

export function ProjectListScreen() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data: projects, isLoading } = useQuery({
    queryKey: ["projects"],
    queryFn: getProjects,
  });

  const deleteMutation = useMutation({
    mutationFn: deleteProject,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["projects"] });
    },
  });

  const shareMutation = useMutation({
    mutationFn: ({ id, email }) => shareProject(id, email),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["projects"] });
    },
  });

  const handleDelete = (id) => {
    deleteMutation.mutate(id);
  };

  const handleShare = (id) => {
    const email = prompt("Paylaşmak istediğiniz kullanıcının e-posta adresini girin:");
    if (email) {
      shareMutation.mutate({ id, email });
    }
  };

  const handleAddNew = () => {
    navigate("/dashboard/projects/add");
  };

  return (
    <AppLayout 
      title="Projeler" 
      actions={[{ name: "Yeni Proje", onClick: handleAddNew }]}
    >
      {isLoading ? (
        <p>Yükleniyor...</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {projects.map((project) => (
            <Card key={project.id}>
              <CardHeader>
                <CardTitle>{project.name}</CardTitle>
                <CardDescription>
                  {project.description.length > 50
                    ? `${project.description.substring(0, 50)}...`
                    : project.description}
                </CardDescription>
              </CardHeader>
              <CardContent>
                {/* Proje içeriği buraya eklenebilir */}
              </CardContent>
              <CardFooter className="flex justify-between">
                <Button variant="outline" size="sm" onClick={() => navigate(`/dashboard/projects/${project.id}`)}>
                  <Pencil className="mr-2 h-4 w-4" />
                  Düzenle
                </Button>
                <Button variant="outline" size="sm" onClick={() => handleShare(project.id)}>
                  <Share className="mr-2 h-4 w-4" />
                  Paylaş
                </Button>
                <Button variant="destructive" size="sm" onClick={() => handleDelete(project.id)}>
                  <Trash className="mr-2 h-4 w-4" />
                  Sil
                </Button>
              </CardFooter>
            </Card>
          ))}
        </div>
      )}
    </AppLayout>
  );
}