import React from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getPlans, deletePlan } from "../../../data";
import { AdminLayout } from "../shared/layout";
import { useNavigate } from "react-router-dom";
import { PlusCircle, Eye, Pencil, Trash2 } from "lucide-react";
import { DataTable } from "@/components/table";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { toast } from "@/components/ui/use-toast";


export function PlanListScreen() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery({
    queryKey: ["plans"],
    queryFn: getPlans,
  });

  const deleteMutation = useMutation({
    mutationFn: deletePlan,
    onSuccess: () => {
      queryClient.invalidateQueries(["plans"]);
      toast.success("Plan başarıyla silindi");
    },
    onError: (error) => {
      toast.error("Plan silinirken bir hata oluştu");
      console.error("Plan silme hatası:", error);
    },
  });

  const actions = [
    {
      name: "Plan Ekle",
      icon: <PlusCircle className="mr-2 h-4 w-4" />,
      onClick: () => navigate("/admin/plans/new"),
    },
  ];

  const handleDelete = (id) => {
    if (window.confirm("Bu planı silmek istediğinizden emin misiniz?")) {
      deleteMutation.mutate(id);
    }
  };

  const columns = [
    { key: "name", label: "Plan Adı" },
    { key: "description", label: "Açıklama" },
    { 
      key: "price", 
      label: "Fiyat", 
      render: (row) => `${row.price} $`
    },
    { key: "interval", label: "Süre" },
    { 
      key: "features", 
      label: "Özellikler", 
      render: (row) => (
        <ul className="list-disc list-inside">
          {row.features && row.features.map((feature, index) => (
            <li key={index} className="text-sm">
              {feature.name}
            </li>
          ))}
        </ul>
      )
    },
    { 
      key: "isActive", 
      label: "Durum", 
      render: (row) => (
        <Badge variant={row.isActive ? "success" : "secondary"}>
          {row.isActive ? "Aktif" : "Pasif"}
        </Badge>
      )
    },
    { 
      key: "actions", 
      label: "İşlemler", 
      render: (row) => (
        <div className="flex justify-end space-x-2">
     
          <Button 
            variant="ghost" 
            size="icon" 
            onClick={() => navigate(`/admin/plans/${row.id}`)}
            className="hover:bg-green-100"
          >
            <Pencil className="h-4 w-4 text-green-500" />
          </Button>
          <Button 
            variant="ghost" 
            size="icon" 
            onClick={() => handleDelete(row.id)}
            className="hover:bg-red-100"
          >
            <Trash2 className="h-4 w-4 text-red-500" />
          </Button>
        </div>
      )
    },
  ];

  return (
    <AdminLayout
      title="Planlar"
      description="Abonelik planlarını buradan yönetin."
      actions={actions}
      loading={isLoading}
    >
      {data && (
        <DataTable 
          data={data.data} 
          columns={columns}
          title="Plan Listesi"
          enableSearch={true}
          enableSort={true}
          enablePagination={true}
          itemsPerPage={10}
        />
      )}
    </AdminLayout>
  );
}