import { Navigate } from "react-router-dom";
import AuthStore from "../stores/AuthStore";

export const ProtectedRoute = ({ children }) => {
  const isLogin = AuthStore.isLogin || AuthStore.isHaveTokenLogin();

  if (!isLogin) {
    return <Navigate to="/login" replace />;
  }

  return children;
};
