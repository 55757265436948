import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { updatePrompt } from "../../../data";
import { Button, Textarea, Input } from "../../../components/ui";

export default function PromptsEditForm({ prompts, onCancel, onSave }) {
  const [editedPrompts, setEditedPrompts] = useState(prompts);
  const updatePromptsMutation = useMutation({
    mutationFn: (updatedPrompts) =>
      Promise.all(
        updatedPrompts.map((prompt) => updatePrompt(prompt.id, prompt))
      ),
    onSuccess: onSave,
  });

  const handlePromptChange = (id, field, value) => {
    setEditedPrompts((prev) =>
      prev.map((prompt) =>
        prompt.id === id ? { ...prompt, [field]: value } : prompt
      )
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updatePromptsMutation.mutate(editedPrompts);
  };

  return (
    <form onSubmit={handleSubmit}>
      {editedPrompts.map((prompt) => (
        <div key={prompt.id}>
          <Input
            value={prompt.title}
            onChange={(e) =>
              handlePromptChange(prompt.id, "title", e.target.value)
            }
          />
          <Textarea
            value={prompt.content}
            onChange={(e) =>
              handlePromptChange(prompt.id, "content", e.target.value)
            }
          />
        </div>
      ))}
      <Button type="submit">Save</Button>
      <Button onClick={onCancel}>Cancel</Button>
    </form>
  );
}
