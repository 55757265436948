import React, { useState, useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { executePrompt, saveChat } from '@/data';
import { Button, Input, Dialog, DialogTrigger, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from '@/components/ui';
import ReactMarkdown from 'react-markdown';

export function AIChat({ projectId, promptIds, taskDescription, initialChat }) {
  const [messages, setMessages] = useState(initialChat?.messages || []);
  const [input, setInput] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [chatTitle, setChatTitle] = useState('');
  const queryClient = useQueryClient();

  useEffect(() => {
    if (initialChat) {
      setMessages(initialChat.messages);
    }
  }, [initialChat]);

  const chatMutation = useMutation({
    mutationFn: executePrompt,
    onSuccess: (data) => {
      const newMessage = { role: 'assistant', content: data.result };
      setMessages(prev => [...prev, newMessage]);
    },
  });

  const saveChatMutation = useMutation({
    mutationFn: saveChat,
    onSuccess: () => {
      queryClient.invalidateQueries(['chats']);
      setIsDialogOpen(false);
    },
  });

  const handleSend = () => {
    if (!input.trim()) return;

    const newMessage = { role: 'user', content: input };
    setMessages(prev => [...prev, newMessage]);

    const chatHistory = [...messages, newMessage];

    chatMutation.mutate({
      projectId,
      promptIds,
      taskDescription,
      userInput: input,
      chatHistory: chatHistory
    });
    setInput('');
  };

  const handleSaveChat = () => {
    saveChatMutation.mutate({
      title: chatTitle,
      messages: messages,
      projectId,
      promptIds,
      taskDescription, // Burada taskDescription'ı ekliyoruz
    });
  };

  const renderMessage = (message) => {
    const isUser = message.role === 'user';
    return (
      <div className={`flex ${isUser ? 'justify-end' : 'justify-start'} mb-4`}>
        <div className={`max-w-3/4 p-3 rounded-lg ${isUser ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'}`}>
          <ReactMarkdown className="prose max-w-none">
            {message.content}
          </ReactMarkdown>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex-1 overflow-y-auto p-4 space-y-4">
        {messages.map((message, index) => (
          <div key={index}>
            {renderMessage(message)}
          </div>
        ))}
      </div>
      <div className="p-4 border-t">
        <div className="flex space-x-2">
          <Input
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Mesajınızı yazın..."
            onKeyPress={(e) => e.key === 'Enter' && handleSend()}
          />
          <Button onClick={handleSend}>
            Gönder
          </Button>
          <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <DialogTrigger asChild>
              <Button variant="outline">Sohbeti Kaydet</Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Sohbeti Kaydet</DialogTitle>
                <DialogDescription>
                  Bu sohbet için bir başlık girin.
                </DialogDescription>
              </DialogHeader>
              <Input
                value={chatTitle}
                onChange={(e) => setChatTitle(e.target.value)}
                placeholder="Sohbet başlığı"
              />
              <DialogFooter>
                <Button onClick={handleSaveChat}>Kaydet</Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </div>
  );
}
