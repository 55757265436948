import { getSettings,updateSettings } from '@/data';
import { makeAutoObservable, runInAction } from 'mobx';


class SettingsStore {
  settings = {
    site_title: '',
    site_description: '',
    site_icon: '',
  };

  constructor() {
    makeAutoObservable(this);
  }

  setSettings(settings) {
    this.settings = settings;
    if (settings.site_title) {
      document.title = settings.site_title;
    }
    if (settings.site_icon) {
      this.updateFavicon(settings.site_icon);
    }
  }

  async fetchSettings() {
    try {
      const response = await getSettings();
      if (typeof response.data === 'object' && response.data !== null) {
        runInAction(() => {
          this.setSettings(response.data);
        });
      } else {
        console.error('Beklenmeyen veri formatı:', response.data);
      }
    } catch (error) {
      console.error('Ayarlar yüklenirken bir hata oluştu:', error);
    }
  }

  updateFavicon(iconBase64) {
    const faviconElement = document.getElementById('favicon');
    const newFavicon = document.createElement('link');
    newFavicon.id = 'favicon';
    newFavicon.rel = 'icon';
    newFavicon.href = iconBase64;

    if (faviconElement) {
      document.head.removeChild(faviconElement);
    }
    document.head.appendChild(newFavicon);

    // Apple Touch Icon için de güncelleme yapalım
    const appleIcon = document.querySelector('link[rel="apple-touch-icon"]');
    if (appleIcon) {
      appleIcon.href = iconBase64;
    }
  }

  async updateSettings(newSettings) {
    try {
      const settingsArray = Object.entries(newSettings).map(([key, value]) => ({ key, value }));
      await updateSettings(settingsArray);
      runInAction(() => {
        this.setSettings(newSettings);
      });
    } catch (error) {
      console.error('Ayarlar güncellenirken bir hata oluştu:', error);
      throw error;
    }
  }
}

export default new SettingsStore();
