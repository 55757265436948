import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { AuthLayout } from "../shared/layout";
import { useForm } from "react-hook-form";

import { useMutation } from "@tanstack/react-query";
import { registerUser } from "../../../data";

export function RegisterScreen() {
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { mutate: registerUserMutation, error } = useMutation({
    mutationKey: "registerUser",
    mutationFn: (data) => {
      return registerUser(data);
    },
    onSuccess: (data) => {
      setMessage("Success: Logged in");
      navigate("/login");
    },
    onError: (error) => {
      setMessage(`Error: ${error.response.data.message.toString()}`);
    },
  });

  const onSubmit = async (data) => {
    const { email, name, password } = data;
    registerUserMutation({ email, name, password });
  };
  const validatePassword = (value) => {
    const hasLowerCase = /[a-z]/.test(value);
    const hasUpperCase = /[A-Z]/.test(value);
    const hasDigit = /\d/.test(value);
    const hasSpecialChar = /[!@#$%^&*]/.test(value);

    if (value.length < 8) {
      return "Password must be at least 8 characters long";
    }

    if (!(hasLowerCase && hasUpperCase && hasDigit && hasSpecialChar)) {
      return "Password must contain at least one lowercase letter, one uppercase letter, one number, and one special character";
    }

    return true;
  };

  return (
    <AuthLayout>
      <h2 className="text-gray-900 text-lg font-medium title-font mb-5">
        Sign Up
      </h2>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className=" mb-4">
          <label htmlFor="full-name" className="leading-7 text-sm ">
            Name
          </label>
          <input
            type="text"
            placeholder="Name"
            {...register("name", {
              required: "Name is required",
            })}
            className="w-full    border  border-gray-300   text-base  py-1 px-3 leading-8 "
          />
          {errors.name && <span>{errors.name.message}</span>}
        </div>

        <div className=" mb-4">
          <label htmlFor="full-name" className="leading-7 text-sm ">
            Email
          </label>
          <input
            type="email"
            placeholder="Email"
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: "Invalid email format",
              },
            })}
            className="w-full    border  border-gray-300   text-base  py-1 px-3 leading-8 "
          />
          {errors.email && <span>{errors.email.message}</span>}
        </div>

        <div className=" mb-4">
          <label htmlFor="email" className="leading-7 text-sm">
            Password
          </label>
          <input
            type="password"
            placeholder="password"
            {...register("password", {
              required: "Password is required",
              validate: validatePassword,
            })}
            className="w-full   border  border-gray-300  text-base  py-1 px-3 leading-8 "
          />
          {errors.password && <span>{errors.password.message}</span>}
        </div>

        <button
          className="shadow-lg  bg-green-200 text-center border-0 py-2 px-8   rounded text-lg"
          type="submit"
        >
          Register
        </button>
      </form>
      {message && <p>{message}</p>}
      {error && <p>{error.message}</p>}

      <Link
        className="shadow-lg  text-center border-0 py-2 px-8   text-lg mt-5 "
        to="/login"
      >
        Login
      </Link>
    </AuthLayout>
  );
}
