import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AuthStore from "../stores/AuthStore";
import { useObserver } from "mobx-react";
import { ProtectedRoute } from "./protected-route";
import { ProtectedAdminRoute } from "./protected-admin-route";
import { getWaitingPageStatus } from "@/data/requests/setting-requests";


// Screens
import * as PublicScreens from "../screens";
import * as ProtectedScreens from "../screens";
import * as AdminScreens from "../screens";
import { NotFoundScreen } from "@/screens/shared/not-found";

const createProtectedRoute = (Component, permission = null) => (
  <ProtectedRoute>
    {permission ? (
      <ProtectedAdminRoute permission={permission}>
        <Component />
      </ProtectedAdminRoute>
    ) : (
      <Component />
    )}
  </ProtectedRoute>
);

const PublicRoutes = (
  <>
    <Route exact path="/" element={<PublicScreens.LandingPageScreen />} />
    <Route path="/about" element={<PublicScreens.AboutPageScreen />} />
    
    <Route
      path="/login"
      element={
        !AuthStore.isLogin || AuthStore.isHaveTokenLogin() ? (
          <PublicScreens.LoginScreen />
        ) : (
          <Navigate to="/" />
        )
      }
    />
    <Route
      path="/register"
      element={
        !AuthStore.isLogin || AuthStore.isHaveTokenLogin() ? (
          <PublicScreens.RegisterScreen />
        ) : (
          <Navigate to="/" />
        )
      }
    />
  </>
);

const ProtectedRoutes = (
  <>
    <Route path="/dashboard" element={createProtectedRoute(ProtectedScreens.HomeScreen)} />
    <Route path="/dashboard/projects" element={createProtectedRoute(ProtectedScreens.ProjectListScreen)} />
    <Route path="/dashboard/projects/add" element={createProtectedRoute(ProtectedScreens.ProjectManageScreen)} />
    <Route path="/dashboard/projects/:id" element={createProtectedRoute(ProtectedScreens.ProjectManageScreen)} />
    <Route path="/dashboard/prompts" element={createProtectedRoute(ProtectedScreens.PromptListScreen)} />
    <Route path="/dashboard/prompts/add" element={createProtectedRoute(ProtectedScreens.PromptManageScreen)} />
    <Route path="/dashboard/prompts/:id" element={createProtectedRoute(ProtectedScreens.PromptManageScreen)} />
    <Route path="/dashboard/chat" element={createProtectedRoute(ProtectedScreens.ChatSetupScreen)} />
    <Route path="/dashboard/chat/:projectId/:promptIds" element={createProtectedRoute(ProtectedScreens.ChatScreen)} />
    <Route path="/dashboard/chat/favorites" element={createProtectedRoute(ProtectedScreens.FavoriteChatsScreen)} />


    <Route path="/profile" element={createProtectedRoute(ProtectedScreens.ProfileScreen)} />
  </>
);

const AdminRoutes = (
  <Route path="admin">
    <Route index element={createProtectedRoute(AdminScreens.AdminDashboardScreen, "Permission_Admin_Dashboard")} />
    <Route path="settings" element={createProtectedRoute(AdminScreens.SettingScreen, "Permission_Admin_Dashboard")} />
    <Route path="users" element={createProtectedRoute(AdminScreens.UserListScreen, "Permission_UserRead")} />
    <Route path="users/:id" element={createProtectedRoute(AdminScreens.UserManageScreen, "Permission_UserRead")} />
    <Route path="users/new" element={createProtectedRoute(AdminScreens.UserManageScreen, "USER_CREATE")} />
    <Route path="roles" element={createProtectedRoute(AdminScreens.RoleListScreen, "ROLE_READ")} />
    <Route path="roles/new" element={createProtectedRoute(AdminScreens.RoleManageScreen, "ROLE_CREATE")} />
    <Route path="roles/:id" element={createProtectedRoute(AdminScreens.RoleManageScreen, "ROLE_UPDATE")} />
    <Route path="plans" element={createProtectedRoute(AdminScreens.PlanListScreen, "Permission_PlanRead")} />
    <Route path="plans/:id" element={createProtectedRoute(AdminScreens.PlanManageScreen, "Permission_PlanRead")} />
    <Route path="plans/new" element={createProtectedRoute(AdminScreens.PlanManageScreen, "PLAN_CREATE")} />
    <Route path="subscriptions" element={createProtectedRoute(AdminScreens.SubscriptionListScreen, "Permission_SubscriptionRead")} />
  </Route>
);

export function BaseRouter() {
  const [waitingPageActive, setWaitingPageActive] = useState(false);

  useEffect(() => {
    const checkWaitingPageStatus = async () => {
      const response = await getWaitingPageStatus();
      setWaitingPageActive(response.data === "active");
    };

    checkWaitingPageStatus();
  }, []);

  return useObserver(() => (
    <Routes>
      {waitingPageActive ? (
        <Route exact path="*" element={<PublicScreens.WaitingPageScreen />} />
      ) : (
        <>
          {PublicRoutes}
          {ProtectedRoutes}
          {AdminRoutes}
        </>
      )}
      <Route path="*" element={<NotFoundScreen />} />
    </Routes>
  ));
}