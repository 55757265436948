import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { getRole, updateRole, createRole, getAllPermissions, getPermissions } from "../../../data";
import { AdminLayout } from "../shared/layout";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { ChevronDown, ChevronUp } from "lucide-react";

export function RoleManageScreen() {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [openGroups, setOpenGroups] = useState({});

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm();

  const isAddMode = !id;

  const { data: roleData, isLoading: isRoleLoading } = useQuery({
    queryKey: ["role", id],
    queryFn: () => getRole(id),
    enabled: !isAddMode,
  });

  const { data: allPermissions, isLoading: isPermissionsLoading } = useQuery({
    queryKey: ["permissions"],
    queryFn: getPermissions,
  });

  useEffect(() => {
    if (!isAddMode && roleData) {
      setValue("name", roleData.data.name);
      setSelectedPermissions(roleData.data.permissions.map(p => p.permissionId));
    }
    if (allPermissions) {
      const allGroups = allPermissions.data.reduce((acc, permission) => {
        const group = permission.name.split('_')[0];
        acc[group] = true;
        return acc;
      }, {});
      setOpenGroups(allGroups);
    }
  }, [isAddMode, roleData, allPermissions, setValue]);

  const mutation = useMutation({
    mutationFn: (data) => isAddMode ? createRole(data) : updateRole(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["roles"] });
      if (!isAddMode) {
        queryClient.invalidateQueries({ queryKey: ["role", id] });
      }
      navigate("/admin/roles");
    },
  });

  const onSubmit = (data) => {
    mutation.mutate({ ...data, permissions: selectedPermissions });
  };

  const handlePermissionToggle = (permissionId) => {
    setSelectedPermissions(prev => 
      prev.includes(permissionId)
        ? prev.filter(id => id !== permissionId)
        : [...prev, permissionId]
    );
  };

  const groupPermissions = (permissions) => {
    const grouped = permissions.data.reduce((acc, permission) => {
      const group = permission.name.split('_')[0];
      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push(permission);
      return acc;
    }, {});

    const groupEntries = Object.entries(grouped);
    const midpoint = Math.ceil(groupEntries.length / 2);
    return {
      leftColumn: Object.fromEntries(groupEntries.slice(0, midpoint)),
      rightColumn: Object.fromEntries(groupEntries.slice(midpoint))
    };
  };

  const toggleGroup = (group) => {
    setOpenGroups(prev => ({ ...prev, [group]: !prev[group] }));
  };

  if (isRoleLoading || isPermissionsLoading) {
    return <AdminLayout>Yükleniyor...</AdminLayout>;
  }

  const { leftColumn, rightColumn } = groupPermissions(allPermissions);

  return (
    <AdminLayout>
      <Card className="w-full max-w-6xl mx-auto">
        <CardHeader>
          <CardTitle>{isAddMode ? "Yeni Rol Oluştur" : "Rol Güncelle"}</CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="name">
                Rol Adı
              </label>
              <Input
                {...register("name", { required: "Rol adı gereklidir" })}
                id="name"
                placeholder="Rol Adı"
              />
              {errors.name && (
                <p className="text-red-500 text-xs mt-1">{errors.name.message}</p>
              )}
            </div>
            <div>
              <h3 className="text-lg font-medium mb-2">İzinler</h3>
              <div className="flex flex-col md:flex-row md:space-x-4">
                <div className="md:w-1/2 space-y-4">
                  {Object.entries(leftColumn).map(([group, permissions]) => (
                    <Collapsible
                      key={group}
                      open={openGroups[group]}
                      onOpenChange={() => toggleGroup(group)}
                    >
                      <CollapsibleTrigger asChild>
                        <Button variant="outline" className="w-full justify-between">
                          {group}
                          {openGroups[group] ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
                        </Button>
                      </CollapsibleTrigger>
                      <CollapsibleContent className="mt-2">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
                          {permissions.map((permission) => (
                            <div key={permission.id} className="flex items-center space-x-2">
                              <Checkbox
                                id={`permission-${permission.id}`}
                                checked={selectedPermissions.includes(permission.id)}
                                onCheckedChange={() => handlePermissionToggle(permission.id)}
                              />
                              <label
                                htmlFor={`permission-${permission.id}`}
                                className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                              >
                                {permission.name.split('_').slice(1).join('_')}
                              </label>
                            </div>
                          ))}
                        </div>
                      </CollapsibleContent>
                    </Collapsible>
                  ))}
                </div>
                <div className="md:w-1/2 space-y-4">
                  {Object.entries(rightColumn).map(([group, permissions]) => (
                    <Collapsible
                      key={group}
                      open={openGroups[group]}
                      onOpenChange={() => toggleGroup(group)}
                    >
                      <CollapsibleTrigger asChild>
                        <Button variant="outline" className="w-full justify-between">
                          {group}
                          {openGroups[group] ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
                        </Button>
                      </CollapsibleTrigger>
                      <CollapsibleContent className="mt-2">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
                          {permissions.map((permission) => (
                            <div key={permission.id} className="flex items-center space-x-2">
                              <Checkbox
                                id={`permission-${permission.id}`}
                                checked={selectedPermissions.includes(permission.id)}
                                onCheckedChange={() => handlePermissionToggle(permission.id)}
                              />
                              <label
                                htmlFor={`permission-${permission.id}`}
                                className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                              >
                                {permission.name.split('_').slice(1).join('_')}
                              </label>
                            </div>
                          ))}
                        </div>
                      </CollapsibleContent>
                    </Collapsible>
                  ))}
                </div>
              </div>
            </div>
            <Button type="submit">
              {isAddMode ? "Oluştur" : "Güncelle"}
            </Button>
          </form>
        </CardContent>
      </Card>
    </AdminLayout>
  );
}