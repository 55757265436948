import api from "../api";

export const getRoles = (data) => {
  return api.getApi().get(api.getBaseUrl() + "roles");
};
export const createRole = (data) => {
  return api.getApi().post(api.getBaseUrl() + "roles", data);
};
export const updateRole = (id, data) => {
  return api.getApi().put(api.getBaseUrl() + "roles/" + id, data);
};
export const getRole = (id) => {
  return api.getApi().get(api.getBaseUrl() + "roles/" + id);
};

export const deleteRole = (id) => {
  return api.getApi().delete(api.getBaseUrl() + "roles/" + id);
};
