import { Link } from "react-router-dom";
import AuthStore from "../../stores/AuthStore";
import { observer } from "mobx-react";
import { Button } from "../ui/button";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "../ui/navigation-menu";

export const NavbarLandingPage = observer(() => {
  return (
    <nav className="border-b-2 py-4">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <Link
            className="flex title-font font-medium items-center text-gray-900"
            to="/"
          >
            Aifelo
          </Link>

          <NavigationMenu>
            <NavigationMenuList>
              <NavigationMenuItem>
                <Link to="/about" legacyBehavior passHref>
                  <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                    About
                  </NavigationMenuLink>
                </Link>
              </NavigationMenuItem>
              {/* Buraya daha fazla menü öğesi ekleyebilirsiniz */}
            </NavigationMenuList>
          </NavigationMenu>

          <div className="flex items-center space-x-4">
            {AuthStore.isLogin || AuthStore.isHaveTokenLogin() ? (
              <Button asChild variant="default">
                <Link to="/dashboard">Go To Dashboard</Link>
              </Button>
            ) : (
              <>
                <Button asChild variant="ghost">
                  <Link to="/login">Login</Link>
                </Button>
                <Button asChild variant="default">
                  <Link to="/register">Register</Link>
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
});