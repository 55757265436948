import React from "react";
import { NavbarAdmin } from "../../../components";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";

export const AdminLayout = (props) => {
  if (props.loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex min-h-screen">
      <NavbarAdmin />

      <main className="flex-1 bg-gray-100 dark:bg-gray-800 p-8">
        <header className="mx-auto p-4 lg:flex lg:items-center lg:justify-between border-b-2 mb-2">
          <div className="flex-1 min-w-0">
            <h1 className="text-3xl font-bold text-gray-900 dark:text-gray-50">
              {props.title}
            </h1>
            <p className="text-gray-500 dark:text-gray-400">
              {props.description}
            </p>
          </div>
          <div className="mt-5 flex lg:mt-0 lg:ml-4">
            {props.actions?.map((item) => (
              <Button
                key={item.name}
                onClick={item.onClick}
                className="ml-3"
              >
                {item.icon && item.icon}
                {item.name}
              </Button>
            ))}
          </div>
        </header>
        <div className="mx-auto">{props.children}</div>
      </main>
    </div>
  );
};