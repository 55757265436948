import React from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getRoles, deleteRole } from "../../../data";
import { AdminLayout } from "../shared/layout";
import { useNavigate } from "react-router-dom";
import { PlusCircle, Pencil, Trash2 } from "lucide-react";
import { DataTable } from "@/components/table";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { toast } from "@/components/ui/use-toast";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";

export function RoleListScreen() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery({
    queryKey: ["roles"],
    queryFn: getRoles,
  });

  const deleteMutation = useMutation({
    mutationFn: deleteRole,
    onSuccess: () => {
      queryClient.invalidateQueries(["roles"]);
      toast({
        title: "Başarılı",
        description: "Rol başarıyla silindi",
        variant: "success",
      });
    },
    onError: (error) => {
      toast({
        title: "Hata",
        description: "Rol silinirken bir hata oluştu",
        variant: "destructive",
      });
      console.error("Rol silme hatası:", error);
    },
  });

  const handleDelete = (id) => {
    deleteMutation.mutate(id);
  };

  const actions = [
    {
      name: "Rol Ekle",
      icon: <PlusCircle className="mr-2 h-4 w-4" />,
      onClick: () => navigate("/admin/roles/new"),
    },
  ];

  const columns = [
    { key: "name", label: "Rol Adı" },
    { 
      key: "permissionCount", 
      label: "İzin Sayısı", 
      render: (row) => (
        <Badge variant="secondary">
          {row.permissionCount} İzin
        </Badge>
      )
    },
    { 
      key: "actions", 
      label: "İşlemler", 
      render: (row) => (
        <div className="flex space-x-2">
          <Button 
            variant="ghost" 
            size="icon" 
            onClick={() => navigate(`/admin/roles/${row.id}`)}
            className="hover:bg-green-100"
          >
            <Pencil className="h-4 w-4 text-green-500" />
          </Button>
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button 
                variant="ghost" 
                size="icon" 
                className="hover:bg-red-100"
              >
                <Trash2 className="h-4 w-4 text-red-500" />
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Rolü Sil</AlertDialogTitle>
                <AlertDialogDescription>
                  Bu rolü silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>İptal</AlertDialogCancel>
                <AlertDialogAction onClick={() => handleDelete(row.id)}>Sil</AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>
      )
    },
  ];

  return (
    <AdminLayout
      title="Roller"
      description="Rollerinizi buradan yönetin."
      actions={actions}
      loading={isLoading}
    >
      {data?.data && (
        <DataTable 
          data={data.data} 
          title="Rol Listesi"
          columns={columns}
          enableSearch={true}
          enableSort={true}
          enablePagination={true}
          itemsPerPage={10}
        />
      )}
    </AdminLayout>
  );
}