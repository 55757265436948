import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { AdminLayout } from "../shared/layout";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Input } from "@/components/ui/input";
import { CardBox } from "../shared/CardBox";
import { Switch } from "@/components/ui/switch";
import { useToast } from "@/components/ui/use-toast";
import {
  getWaitingPageStatus,
  updateWaitingPageStatus,
} from "@/data/requests/setting-requests";
import { useSettings } from "@/context/SettingsProvider";


export const SettingScreen = observer(() => {
  const { toast } = useToast();
  const settingsStore = useSettings();
  const [status, setStatus] = useState("inactive");

  useEffect(() => {
    const fetchStatus = async () => {
      const response = await getWaitingPageStatus();
      setStatus(response.data);
    };

    fetchStatus();
    settingsStore.fetchSettings();
  }, [settingsStore]);

  const updateStatus = async (newStatus) => {
    try {
      await updateWaitingPageStatus({ status: newStatus });
      setStatus(newStatus);
      toast({
        title: "Başarılı",
        description: "Bekleme sayfası durumu güncellendi.",
      });
    } catch (error) {
      toast({
        title: "Hata",
        description: "Bekleme sayfası durumu güncellenirken bir hata oluştu.",
        variant: "destructive",
      });
    }
  };

  const handleSave = async () => {
    try {
      await settingsStore.updateSettings(settingsStore.settings);
      toast({
        title: "Başarılı",
        description: "Genel ayarlar kaydedildi.",
      });
    } catch (error) {
      toast({
        title: "Hata",
        description: "Genel ayarlar kaydedilirken bir hata oluştu.",
        variant: "destructive",
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    settingsStore.setSettings({ ...settingsStore.settings, [name]: value });
  };

  const pageSettings = {
    title: "Genel Ayarlar",
    actions: [
      {
        name: "Kaydet",
        onClick: handleSave,
      },
    ],
  };

  return (
    <AdminLayout title={pageSettings.title} actions={pageSettings.actions}>
      <div className="space-y-6">
        <CardBox
          title="Genel Ayarlar"
          description="Site ayarlarınızı güncelleyin"
        >
          <form className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="site_title">Site Başlığı</Label>
              <Input
                id="site_title"
                name="site_title"
                placeholder="Site başlığını girin"
                value={settingsStore.settings.site_title}
                onChange={handleInputChange}
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="site_description">Site Açıklaması</Label>
              <Textarea
                id="site_description"
                name="site_description"
                placeholder="Site açıklamasını girin"
                value={settingsStore.settings.site_description}
                onChange={handleInputChange}
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="site_icon">Site Logosu</Label>
              <div className="flex items-center space-x-4">
                <img
                  alt="Site Logosu"
                  className="rounded-md"
                  height={80}
                  src={settingsStore.settings.site_icon || "/placeholder.svg"}
                  style={{
                    aspectRatio: "80/80",
                    objectFit: "cover",
                  }}
                  width={80}
                />
                <Input
                  id="site_icon"
                  name="site_icon"
                  type="text"
                  placeholder="Logo URL'sini girin"
                  value={settingsStore.settings.site_icon}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </form>
        </CardBox>

        <CardBox
          title="Bekleme Sayfası Ayarları"
          description="Bekleme sayfasını etkinleştirirseniz, site bakımdayken kullanıcılar bu sayfayı görecektir"
        >
          <div className="flex items-center space-x-2">
            <Switch
              id="waiting-page"
              checked={status === "active"}
              onCheckedChange={(checked) =>
                updateStatus(checked ? "active" : "inactive")
              }
            />
            <Label htmlFor="waiting-page">Bekleme Sayfasını Etkinleştir</Label>
          </div>
        </CardBox>
      </div>
    </AdminLayout>
  );
});