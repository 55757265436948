import React from "react";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";

export function NotFoundScreen() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-background">
      <h1 className="text-4xl font-bold mb-4">404</h1>
      <p className="text-xl mb-8">Sayfa bulunamadı</p>
      <Button onClick={() => navigate("/")}>Ana Sayfaya Dön</Button>
    </div>
  );
}
