import React from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getSubscriptions, updateSubscription, deleteSubscription } from "../../../data";
import { AdminLayout } from "../shared/layout";
import { useNavigate } from "react-router-dom";
import { PlusCircle, Eye, Pencil, Trash2 } from "lucide-react";
import { DataTable } from "@/components/table";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { toast } from "@/components/ui/use-toast";


export function SubscriptionListScreen() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery({
    queryKey: ["subscriptions"],
    queryFn: getSubscriptions,
  });

  const updateMutation = useMutation({
    mutationFn: updateSubscription,
    onSuccess: () => {
      queryClient.invalidateQueries(["subscriptions"]);
      toast.success("Abonelik başarıyla güncellendi");
    },
    onError: (error) => {
      toast.error("Abonelik güncellenirken bir hata oluştu");
      console.error("Abonelik güncelleme hatası:", error);
    },
  });

  const deleteMutation = useMutation({
    mutationFn: deleteSubscription,
    onSuccess: () => {
      queryClient.invalidateQueries(["subscriptions"]);
      toast.success("Abonelik başarıyla silindi");
    },
    onError: (error) => {
      toast.error("Abonelik silinirken bir hata oluştu");
      console.error("Abonelik silme hatası:", error);
    },
  });

  


  const columns = [
    { 
      key: "user", 
      label: "Kullanıcı", 
      render: (row) => row.user.name
    },
    { 
      key: "plan", 
      label: "Plan", 
      render: (row) => row.plan.name
    },
    { 
      key: "startDate", 
      label: "Başlangıç Tarihi", 
      render: (row) => new Date(row.startDate).toLocaleDateString()
    },
    { 
      key: "endDate", 
      label: "Bitiş Tarihi", 
      render: (row) => new Date(row.endDate).toLocaleDateString()
    },
    { 
      key: "status", 
      label: "Durum", 
      render: (row) => (
        <Badge variant={row.status === "ACTIVE" ? "success" : "secondary"}>
          {row.status === "ACTIVE" ? "Aktif" : "Pasif"}
        </Badge>
      )
    },
    { 
      key: "actions", 
      label: "İşlemler", 
      render: (row) => (
        <div className="flex justify-end space-x-2">
         
          
        </div>
      )
    },
  ];

  return (
    <AdminLayout
      title="Abonelikler"
      description="Kullanıcı aboneliklerini buradan yönetin."
      
      loading={isLoading}
    >
      {data && (
        <DataTable 
          data={Array.isArray(data) ? data : [data]} 
          columns={columns}
          title="Abonelik Listesi"
          enableSearch={true}
          enableSort={true}
          enablePagination={true}
          itemsPerPage={10}
        />
      )}
    </AdminLayout>
  );
}