import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { updateProject } from "../../../data";
import { Button, Textarea } from "../../../components/ui";

export default function ProjectEditForm({ project, onCancel, onSave }) {
  const [description, setDescription] = useState(project.description);
  const updateProjectMutation = useMutation({
    mutationFn: (updatedProject) => updateProject(project.id, updatedProject),
    onSuccess: onSave,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    updateProjectMutation.mutate({ description });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Textarea
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <Button type="submit">Save</Button>
      <Button onClick={onCancel}>Cancel</Button>
    </form>
  );
}
