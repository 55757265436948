import { useQuery } from "@tanstack/react-query";
import { getActivePlans } from "../../data";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Check, X, Star, AlertCircle } from "lucide-react";
import CheckoutButton from "@/components/checkout-button";

import { stripePromise } from "@/lib/stripe";

const iconMap = {
  check: Check,
  cancel: X,
  star: Star,
  alert: AlertCircle,
  // İhtiyacınız olan diğer ikonları buraya ekleyebilirsiniz
};

export function PlanListComponent() {
  const { data, isLoading } = useQuery({
    queryKey: ["plans"],
    queryFn: getActivePlans,
  });

  if (isLoading) {
    return <div className="text-center">Planlar yükleniyor...</div>;
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {data && data.data.length ? (
        data.data.map((plan) => (
          <Card key={plan.id} className="flex flex-col">
            <CardHeader>
              <CardTitle className="flex justify-between items-center">
                {plan.name}
                <Badge variant="secondary">{plan.interval}</Badge>
              </CardTitle>
            </CardHeader>
            <CardContent className="flex-grow">
              <p className="text-3xl font-bold mb-4">{plan.price} $</p>
              <p className="text-gray-600 dark:text-gray-300 mb-4">{plan.description}</p>
              <ul className="space-y-2">
                {plan.features && plan.features.map((feature, index) => {
                  const IconComponent = feature.icon ? iconMap[feature.icon] || Check : Check;
                  const iconColor = feature.icon === 'cancel' ? 'text-red-500' : 'text-green-500';
                  return (
                    <li key={index} className="flex items-center">
                      <IconComponent className={`mr-2 h-4 w-4 ${iconColor}`} />
                      <span>{feature.name}</span>
                    </li>
                  );
                })}
              </ul>
            </CardContent>
            <CardFooter>
              <Elements stripe={stripePromise}>
                <CheckoutButton priceId={plan.planPriceId} className="w-full" />
              </Elements>
            </CardFooter>
          </Card>
        ))
      ) : (
        <div className="col-span-full text-center bg-red-100 dark:bg-red-900 p-4 rounded-lg">
          Plan bulunamadı
        </div>
      )}
    </div>
  );
}