import Api from "../api";

export const getPrompts = async () => {
  const response = await Api.getApi().get("/prompts");
  return response.data;
};

export const getPrompt = async (id) => {
  const response = await Api.getApi().get(`/prompts/${id}`);
  return response.data;
};

export const createPrompt = async (promptData) => {
  const response = await Api.getApi().post("/prompts", promptData);
  return response.data;
};

export const updatePrompt = async (id, promptData) => {
  const response = await Api.getApi().put(`/prompts/${id}`, promptData);
  return response.data;
};

export const deletePrompt = async (id) => {
  const response = await Api.getApi().delete(`/prompts/${id}`);
  return response.data;
};

export const sharePrompt = async (id, userEmail) => {
  const response = await Api.getApi().post(`/prompts/${id}/share`, { userEmail });
  return response.data;
};

export const executePrompt = async ({ projectId, promptIds, taskDescription, userInput, chatHistory }) => {
  const response = await Api.getApi().post(`/ai/process`, {
    projectId,
    promptIds,
    taskDescription,
    userInput,
    chatHistory
  });
  return response.data;
};
export const getChatSetupData = async () => {
  const response = await Api.getApi().get('/chat-setup');
  return response.data;
};