import Api from '../api';

export const saveChat = async (chatData) => {
  const response = await Api.getApi().post('/chats', chatData);
  return response.data;
};

export const getUserChats = async (projectId) => {
  const response = await Api.getApi().get('/chats', {
    params: { projectId }
  });
  return response.data;
};

export const getChatById = async (chatId) => {
  const response = await Api.getApi().get(`/chats/${chatId}`);
  return response.data;
};

export const updateChat = async (chatId, chatData) => {
  const response = await Api.getApi().put(`/chats/${chatId}`, chatData);
  return response.data;
};

export const toggleFavorite = async (chatId) => {
  const response = await Api.getApi().put(`/chats/${chatId}/favorite`);
  return response.data;
};

export const deleteChat = async (chatId) => {
  const response = await Api.getApi().delete(`/chats/${chatId}`);
  return response.data;
};
export const getFavoriteChats = async () => {
  const response = await Api.getApi().get('/favoritesChat');
  return response.data;
};