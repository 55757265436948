import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { NavbarLandingPage } from "../../../components";
import { useSettings } from "@/context/SettingsProvider";

export const LandingPageLayout = (props) => {
  const settingsStore = useSettings();
  const { settings } = settingsStore;
  
  console.log("Ayarlar:", settings); // Ayarları kontrol etmek için log eklendi
  
  return (
    <>
      <Helmet>
        <title>{settings.site_title}</title>
        <meta name="description" content={settings.site_description} />
        <link rel="icon" href={settings.site_icon} />
      </Helmet>
    
      <NavbarLandingPage />
      <main className="bg-red-100 h-screen">{props.children}</main>
      <footer className=" h-20">
        <div className="flex justify-center items-center h-full">
          appstarter.dev @ 2024
        </div>
      </footer>
    </>
  );
};
