import React from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm, Controller } from "react-hook-form";
import { AppLayout } from "../shared/layout";
import { getProject, createProject, updateProject } from "../../../data";
import { useNavigate, useParams } from "react-router-dom";
import { CardBox } from "@/screens/admin/shared/CardBox";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { toast } from "@/components/ui/use-toast";

export function ProjectManageScreen() {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const isEditMode = !!id;

  const { data: projectData, isLoading: isProjectLoading } = useQuery({
    queryKey: ["project", id],
    queryFn: () => getProject(id),
    enabled: isEditMode,
    staleTime: 0, // Her zaman yeni veri çek
    cacheTime: 0, // Önbelleğe alma
  });

  const { control, handleSubmit, formState: { errors }, reset } = useForm({
    defaultValues: {
      name: "",
      description: "",
    },
  });

  React.useEffect(() => {
    if (projectData) {
      reset({
        name: projectData.name,
        description: projectData.description,
      });
    }
  }, [projectData, reset]);

  const mutation = useMutation({
    mutationFn: (data) => isEditMode ? updateProject(id, data) : createProject(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["projects"]);
      toast({
        title: "Başarılı",
        description: isEditMode ? "Proje güncellendi" : "Proje oluşturuldu",
        variant: "success",
      });
      navigate("/dashboard/projects");
    },
    onError: (error) => {
      toast({
        title: "Hata",
        description: "Bir hata oluştu",
        variant: "destructive",
      });
      console.error("Proje işlem hatası:", error);
    },
  });

  const onSubmit = (data) => {
    mutation.mutate(data);
  };

  if (isProjectLoading && isEditMode) {
    return <AppLayout title="Proje Yönetimi">Yükleniyor...</AppLayout>;
  }

  return (
    <AppLayout title={isEditMode ? "Proje Güncelle" : "Yeni Proje Ekle"}>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
        <CardBox>
          <h2 className="text-2xl font-bold mb-4">Proje Bilgileri</h2>
          <div className="space-y-4">
            <div>
              <Label htmlFor="name">Proje Adı</Label>
              <Controller
                name="name"
                control={control}
                rules={{ required: "Proje adı gereklidir" }}
                render={({ field }) => <Input id="name" {...field} />}
              />
              {errors.name && <p className="text-red-500">{errors.name.message}</p>}
            </div>

            <div>
              <Label htmlFor="description">Açıklama</Label>
              <Controller
                name="description"
                control={control}
                render={({ field }) => <Textarea id="description" {...field} />}
              />
            </div>
          </div>
        </CardBox>
        
        <Button type="submit" className="w-full">
          {isEditMode ? "Proje Güncelle" : "Proje Oluştur"}
        </Button>
      </form>
    </AppLayout>
  );
}
