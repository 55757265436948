import Api from "../api";

export const getProjects = async () => {
  const response = await Api.getApi().get("/projects");
  return response.data;
};

export const getProject = async (id) => {
  const response = await Api.getApi().get(`/projects/${id}`);
  return response.data;
};

export const createProject = async (projectData) => {
  const response = await Api.getApi().post("/projects", projectData);
  return response.data;
};

export const updateProject = async (id, projectData) => {
  const response = await Api.getApi().put(`/projects/${id}`, projectData);
  return response.data;
};

export const deleteProject = async (id) => {
  const response = await Api.getApi().delete(`/projects/${id}`);
  return response.data;
};

export const shareProject = async (id, userEmail) => {
  const response = await Api.getApi().post(`/projects/${id}/share`, { userEmail });
  return response.data;
};