import React, { useEffect } from "react";
import AuthStore from "../../../stores/AuthStore";
import { AppLayout } from "../shared/layout";

import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { PlanListComponent } from "@/screens/shared/plan-list";

export function HomeScreen() {
  useEffect(() => {});
  return (
    <AppLayout title="Dashboard">
      <div className="space-y-8">
       

        <Card>
          <CardHeader>
            <CardTitle>Mevcut Planlar</CardTitle>
          </CardHeader>
          <CardContent>
            <PlanListComponent />
          </CardContent>
        </Card>
      </div>
    </AppLayout>
  );
}